import React from "react";
import {
  AccordionContent,
  AccordionHeader,
  AccordionHeading,
  AccordionItem,
  IconDiv,
} from "./Accordion.styles";
import { RowDiv, Theme } from "@/outscal-commons-frontend/Styled";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";
import TextSection from "../TextSection/TextSection.controller";

function AccordionView({
  toggleAccordion,
  title,
  isOpen,
  description,
  setTextAsHtml,
  alignment,
  defaultHeight,
  analyticsKey,
  gap,
  contentPadding,
}) {
  return (
    <AccordionItem isOpen={isOpen} gap={gap}>
      <AccordionHeader
        onClick={() => toggleAccordion()}
        view-analytics={[UIElements.CARD, analyticsKey]}
        data-analytics={[
          UIElements.CARD,
          `${isOpen ? "close" : "open"}-${analyticsKey}`,
        ]}
        contentPadding={contentPadding}
      >
        <RowDiv justifyContent="space-between" alignItems="center">
          <AccordionHeading>{title}</AccordionHeading>
          <IconDiv
            color={Theme.colors.monacoBlack}
            rotation={isOpen ? `180deg` : "0deg"}
          ></IconDiv>
        </RowDiv>
      </AccordionHeader>
      <AccordionContent
        maxHeight={isOpen ? `6000px` : "0px"}
        expanded={isOpen}
        contentPadding={contentPadding}
      >
        {setTextAsHtml ? (
          description
        ) : (
          <TextSection
            bodyText={description}
            alignment={alignment}
            defaultHeight={defaultHeight}
            ellipsizeText={true}
            setTextAsHtml={setTextAsHtml}
            color={"black"}
          />
        )}
      </AccordionContent>
    </AccordionItem>
  );
}

export default AccordionView;
