import styled from "styled-components";
import { IoIosArrowDown } from "react-icons/io";

export const AccordionItem = styled.div`
  background-color: ${(p) => p.theme.colors.white};
  border-radius: ${(p) => p.theme.borderRadius.xxs};
  margin-bottom: ${(p) => p.gap};
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  width: 100%;
  border: 1px solid ${(p) => p.theme.colors.borderGray};
  box-shadow: ${(p) => p.isOpen && `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px`};
  &:hover {
    box-shadow: var(--section-card-box-shadow);
  }
`;

export const IconDiv = styled(IoIosArrowDown)`
  --icon-size: 24px;
  height: var(--icon-size);
  width: var(--icon-size);
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease-in-out;
  transform: rotate(${(p) => p.rotation});
`;

export const AccordionHeader = styled.div`
  align-items: center;
  padding: ${(p) => p.contentPadding};
  position: relative;

  @media screen and (max-width: 767px) {
    padding: 12px;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const AccordionContent = styled.div`
  overflow: hidden;
  height: fit-content;
  max-height: ${(p) => p.maxHeight};
  padding: ${(p) => (p.expanded ? p.contentPadding : "0 16px")};
  padding-top: 0px;
  transition: all 0.3s ease-in-out;

  @media screen and (max-width: 767px) {
    padding: ${(p) => (p.expanded ? "12px" : "0 12px")};
  }
`;

export const AccordionHeading = styled.h3`
  font-size: ${(p) => p.theme.fontSize.lg};
  font-weight: ${(p) => p.theme.fontWeight.semiBold};
  width: calc(100% - 32px);
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
