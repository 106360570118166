import React, { useState } from "react";
import AccordionView from "./Accordion.view";
import { removeHtmlSpace, removeHtmlTags } from "@/utils/commonUtils";

function AccordionController({
  title,
  analyticsKey = "accordion",
  description,
  defaultOpen = false,
  setTextAsHtml = true,
  alignment = "left",
  defaultHeight = 100,
  gap = "16px",
  contentPadding = "16px",
}) {
  const [isOpen, setOpen] = useState(defaultOpen);
  const toggleAccordion = () => {
    setOpen(!isOpen);
  };
  return (
    <AccordionView
      analyticsKey={analyticsKey}
      toggleAccordion={toggleAccordion}
      isOpen={isOpen}
      title={title}
      description={
        setTextAsHtml
          ? description
          : removeHtmlSpace(removeHtmlTags(description))
      }
      setTextAsHtml={setTextAsHtml}
      alignment={alignment}
      defaultHeight={defaultHeight}
      gap={gap}
      contentPadding={contentPadding}
    />
  );
}

export default AccordionController;
