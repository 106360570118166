import Link from "next/link";
import React from "react";
import { Root, CTA } from "./TextSection.styles";
import EllipsizedText from "../EllipsizedText/EllipsizedText.controller";
import { H2, Text } from "@/outscal-commons-frontend/Styled/TextStyles";

const TextSectionView = ({
  heading,
  bodyText,
  alignment,
  actionText,
  actionFunction,
  redirectionLink,
  gridGap,
  ellipsizeText,
  setTextAsHtml,
  defaultHeight,
  color,
}) => {
  return (
    <Root alignment={alignment} gridGap={gridGap}>
      {heading && <H2 align={alignment}>{heading}</H2>}
      {bodyText && (
        <Text align={alignment} color="textDarkerGrey">
          {ellipsizeText ? (
            <EllipsizedText
              text={bodyText}
              defaultHeight={defaultHeight}
              showSeeMore={ellipsizeText}
              textAlign={alignment}
              setTextAsHtml={setTextAsHtml}
              color={color}
            />
          ) : (
            bodyText
          )}
        </Text>
      )}
      {redirectionLink && (
        <Link href={redirectionLink} target="_blank" onClick={actionFunction}>
          <CTA>{actionText}</CTA>
        </Link>
      )}
    </Root>
  );
};

export default TextSectionView;
